import React from 'react';
import Header from './Header';
import Modals from './Modals';
import Carousel from 'nuka-carousel';
import ReactPaginate from 'react-paginate';
import { Redirect } from 'react-router-dom';
import '../assets/css/App.scss';
const API_URL = 'https://greenfield.emlsdr.ru/backend/api';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      submit: false,
      loaded: false,
      webp: true,
      loading_codes: true,
      codes: [],
      codes2: [],
      page: 1,
      prizeType: 0,
      loading_prizeMy: true,
      emptyText:
        'Пришлите Ваш первый чек  с покупкой чая Greenfield, копите баллы (10 рублей = 1 балл) и обменивайте их на гарантированные подарки.',
      emptyText2:
        'У Вас ещё нет подарков. Присылайте чеки с Greenfield, копите баллы (10 рублей = 1 балл) и обменивайте их на гарантированные подарки.',
      emptyText3:
        'Пришлите Ваш первый чек  с покупкой чая Greenfield, копите баллы (10 рублей = 1 балл) и обменивайте их на гарантированные подарки.',
      Offset: 0,
      data: [],
      prizeMy: [],
      perPage: 10,
      currentPage: 1,
      name: '',
      sortPar: 'id',
      sortNapr: 1,
      banner: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.showLkModal = this.showLkModal.bind(this);
    this.loginShow = this.loginShow.bind(this);
    this.info7Show = this.info7Show.bind(this);
    this.info8Show = this.info8Show.bind(this);
    this.modal__new_action = this.modal__new_action.bind(this);
    this.info10Show = this.info10Show.bind(this);
    this.showChangeModal = this.showChangeModal.bind(this);
    this.showChangeDataModal = this.showChangeDataModal.bind(this);
    this.showFeedackModal = this.showFeedackModal.bind(this);
    this.prizeShow = this.prizeShow.bind(this);
    this.checkShow = this.checkShow.bind(this);
    this.changeShow = this.changeShow.bind(this);
    this.addDataShow = this.addDataShow.bind(this);
  }
  componentDidMount() {
    console.log(this.props.data.state.banner);
  }
  showLkModal = () => {
    this.modals.lkShow();
  };
  loginShow = () => {
    this.modals.loginShow();
  };
  info8Show = () => {
    this.modals.info8Show();
  };
  modal__new_action = async () => {
    const formData = new FormData();
    await fetch(API_URL + '/openBanner', {
      method: 'POST',
      headers: {
        'X-Auth-Token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.props.data.setState({
          banner: true,
        });
      });
    this.modals.infoNewAction();
  };
  info10Show = (title, text) => {
    this.modals.info10Show(title, text);
  };
  info7Show = () => {
    this.modals.info7Show(
      'На данный момент на сайте проводятся технические работы. Приносим извинения за неудобства. Зарегистрировать чек можно в чат-ботах',
    );
  };
  changeShow = () => {
    this.modals.changeShow();
  };
  showChangeModal = () => {
    this.modals.changeShow();
  };
  showChangeDataModal = () => {
    this.modals.changeDataShow();
  };
  addDataShow = () => {
    this.modals.addDataShow();
  };
  showFeedackModal = () => {
    this.modals.feedbackShow();
  };
  prizeShow = (name, price, id) => {
    this.modals.prizeShow(name, price, id);
  };
  checkShow = (img) => {
    this.modals.checkShow(img);
  };
  componentDidMount() {
    this.getCodes(this.state.page, this.state.prizeType);
    this.getPrizeMy();
    document.body.classList.add('cabinet');
    setTimeout(() => {
      window.pix_land('11822', '9607', '');
    }, 500);
  }
  getCodes = async (page, type) => {
    await fetch(API_URL + '/getActivity?page=' + page + '&type=' + type, {
      method: 'GET',
      headers: {
        'X-Auth-Token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.result === true) {
          this.setState({
            perPage: response.data.paging.total,
            codes: response.data.rows,
            loading_codes: false,
          });
        }
      });
  };
  getCodes2 = async (page, type) => {
    await fetch(API_URL + '/getCodes?page=' + page + '&type=' + type, {
      method: 'GET',
      headers: {
        'X-Auth-Token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.result === true) {
          this.setState({
            perPage: response.data.paging.total,
            codes: response.data.rows,
            loading_codes: false,
          });
        }
      });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        page: selectedPage + 1,
        offset: offset,
      },
      () => {
        this.getCodes(this.state.page, this.state.prizeType);
      },
    );
  };
  sort = (e, parr, napr) => {
    if (napr === 1) {
      this.setState({ codes: e.sort((a, b) => a[parr] - b[parr]), sortNapr: 2, sortPar: parr });
    } else if (napr === 3) {
      this.setState({ codes: e.sort((a, b) => a[parr] - b[parr]), sortPar: parr });
    } else {
      // console.log(napr);
      this.setState({ codes: e.sort((a, b) => b[parr] - a[parr]), sortNapr: 1, sortPar: parr });
    }
  };
  renderWinners = () => {
    if (this.state.codes.length > 0) {
      return this.state.codes.map((code, index) => (
        <div key={'winner_' + index.toString()} className="table__row d-flex align-items-start">
          <div className="tb__col tb__col-1 d-col-4 col__date">{code.date_create}</div>
          <div className="tb__col tb__col-2 d-col-4 col__check">
            {code.check_photo && (
              <div
                className="ico ico-check"
                onClick={() => {
                  this.checkShow(code.check_photo);
                }}></div>
            )}

            {code.check_number ? code.check_number : code.desc}
          </div>
          <div className={'tb__col tb__col-3 d-col-4 win col__win'}>
            <span className="popover">
              {code.check_number ? (
                code.sum > 0 ? (
                  <span className="popover-content">{code.check_status}</span>
                ) : code.check_reason != null ? (
                  <span className="popover-content">{code.check_reason}</span>
                ) : (
                  ''
                )
              ) : code.desc ? (
                <span className="popover-content"> {code.desc}</span>
              ) : (
                ''
              )}
              {code.check_status ? (code.sum > 0 ? code.sum : code.check_status) : code.sum}
            </span>
          </div>
        </div>
      ));
    } else {
      if (this.state.prizeType === 2) {
        return <div>{this.state.emptyText2}</div>;
      } else {
        return <div>{this.state.emptyText}</div>;
      }
    }
  };

  renderError = () => {
    if (this.state.emailError === true) {
      return (
        <div
          className="error__message"
          onClick={() => {
            this.setState({ emailError: false });
          }}>
          {this.state.emailErrorText}
        </div>
      );
    }
  };
  btnUploadCheck = () => {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      return (
        <button
          id="apm-scan-qr"
          className="upload-check btn btn-2 btn-arrow-1"
          onClick={() => {
            if (typeof window.__GetI === 'undefined') {
              window.__GetI = [];
            }
            (function () {
              var p = {
                type: 'VIEW',
                /* config START */
                site_id: '8835',
                product_id: '',
                product_price: '',
                category_id: '',
                pixel_id: 'Reg_Chek',
                /* config END */
              };
              window.__GetI.push(p);
              var domain =
                typeof window.__GetI_domain == 'undefined' ? 'px.adhigh.net' : window.__GetI_domain;
              var src =
                ('https:' == document.location.protocol ? 'https://' : 'http://') +
                domain +
                '/p.js';
              var script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = src;
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(script, s);
            })();

            // window.dataLayer.push({
            //   event: 'gaEvent',
            //   eventCategory: 'internal',
            //   eventAction: 'click',
            //   eventLabel: 'load_cheque_cabinet',
            // });
          }}>
          зарегистрировать чек
        </button>
      );
    }
  };
  NumberCart = () => {
    if (
      localStorage.getItem('cardNumber') &&
      localStorage.getItem('cardNumber') !== '' &&
      localStorage.getItem('cardNumber') !== 'null'
    ) {
      return (
        <div className="numberCart">
          Карта лояльности: <span>{localStorage.getItem('cardNumber')}</span>
        </div>
      );
    }
  };
  scores = () => {
    if (
      localStorage.getItem('scores') &&
      localStorage.getItem('scores') !== '' &&
      localStorage.getItem('scores') !== 'null'
    ) {
      return (
        <div className="numberCart">
          Накоплено баллов: <span>{localStorage.getItem('scores')}</span>
        </div>
      );
    }
  };
  renderPrizes = () => {
    // console.log(this.props.data.state.prizes);
    if (this.props.data.state.prizes.length > 0) {
      return this.props.data.state.prizes?.map(
        (pr, index) =>
          pr.showInProfile && (
            <div
              key={index}
              className={
                'section-lk-prize d-col-6 section-lk-prize-' +
                pr.id +
                (pr.price <= localStorage.getItem('balance') && !pr.limit ? ' active' : '')
              }>
              <div className={'section-lk-prize-img section-lk-prize-img-' + pr.id}>
                {pr.id < 9 ? (
                  <img src={require('../assets/img/prizes-min/prize' + pr.id + '.jpg').default} />
                ) : (
                  <img
                    src={require('../assets/img/new/prizes-min/prize' + pr.id + '.jpg').default}
                  />
                )}
                {/* {console.log(Number(pr.price))}
                {console.log(Number(localStorage.getItem('balance')))} */}
                {Number(pr.price) <= Number(this.props.data.state.balance) ? (
                  !pr.limit ? (
                    <div
                      className="btn-order btn-arrow-2"
                      onClick={() => {
                        // this.prizeShow(pr.name, pr.price, pr.id);
                        this.info8Show();
                      }}>
                      <span>заказать</span>
                    </div>
                  ) : (
                    <div className="noActive">Лимит исчерпан</div>
                  )
                ) : (
                  <div className="noActive">недостаточно баллов</div>
                )}
              </div>
              <div className="section-lk-prize-text">
                <div className="section-lk-prize-name">{pr.name}</div>

                <div className={'section-lk-prize-price section-lk-prize-' + pr.id + '-price'}>
                  {pr.price}
                  <span> баллов</span>
                </div>
                {Number(pr.price) <= Number(this.props.data.state.balance) && !pr.limit && (
                  <div
                    className="btn-order btn-order-2 btn-arrow-2"
                    onClick={() => {
                      // this.prizeShow(pr.name, pr.price, pr.id);
                      this.info8Show();
                    }}>
                    <span>заказать</span>
                  </div>
                )}
              </div>
            </div>
          ),
      );
    } else {
      return <div className="table__row d-flex align-items-start"></div>;
    }
  };
  getPrizeMy = async () => {
    await fetch(API_URL + '/getPrizeMy', {
      method: 'GET',
      headers: {
        'X-Auth-Token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response.data);
        if (response.result === true) {
          this.setState({
            prizeMy: response.data,
            loading_prizeMy: false,
          });
        }
      });
  };
  renderPrizeMy = () => {
    if (this.state.prizeMy.length > 0) {
      return this.state.prizeMy?.map((pr, index) => (
        <div key={index} className="section-lk-myOrder-item">
          <div className={'section-lk-myOrder-item-img ' + pr.id}>
            {/* {console.log(pr.id)} */}
            {pr.id < 10 ? (
              <img src={require('../assets/img/prizes/modal/prize' + pr.id + '.png').default} />
            ) : (
              <img
                src={require('../assets/img/new/prizes/modal/lc/prize' + pr.id + '.png').default}
              />
            )}
          </div>
          <div className="section-lk-myOrder-item-content">
            <div className="section-lk-myOrder-item-content-title">{pr.name}</div>
            <div className="section-lk-myOrder-item-content-step">{pr.status.text}</div>
            {pr.prize_code !== null ? (
              <button
                className="prize-btn btn btn-2"
                onClick={() => this.info10Show(pr.name, pr.prize_code)}>
                Получить приз
              </button>
            ) : (
              ''
            )}
            <div className="section-lk-myOrder-item-content-track">
              {pr.status.type === 1 && pr.status.date}
              {pr.status.type === 2 && (
                <a href={'https://www.pochta.ru/tracking#' + pr.status.track} target="_BLANK">
                  трек-номер {pr.status.track}
                </a>
              )}
            </div>
          </div>
        </div>
      ));
    } else {
      return <div className="table__row d-flex align-items-start"></div>;
    }
  };
  render() {
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== null &&
      localStorage.getItem('auth_key') !== undefined
    ) {
      // if (!localStorage.getItem('auth_key')) {
      return (
        <div className="app">
          <Header
            link={this.props.link}
            showLkModal={this.showLkModal}
            loginShow={this.loginShow}
            showChangeModal={this.showChangeModal}
            showChangeDataModal={this.showChangeDataModal}
            data={this.props.data}
            getCodes={this.getCodes}
          />

          <div id="fullpage-wrap">
            <div id="fullpage-wrapper">
              <div className="section  section-template2 section-lk">
                <div className="section-menu-cabinet">
                  <div className="container">
                    <ul className="menu-cabinet">
                      <li>
                        <a
                          href="#ball"
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'points',
                            });
                          }}>
                          мои баллы
                        </a>
                      </li>
                      <li>
                        <a
                          href="#prize"
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'prizes',
                            });
                          }}>
                          подарки
                        </a>
                      </li>
                      <li>
                        <a
                          href="#check"
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'checks',
                            });
                          }}>
                          загруженные чеки
                        </a>
                      </li>
                      <li>
                        <a
                          href="#order"
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'orders',
                            });
                          }}>
                          мои заказы
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {!this.props.data.state.banner ? (
                  <div className="new_action_bann" onClick={this.modal__new_action}></div>
                ) : (
                  ''
                )}
                <div className="section-lk-data">
                  <div className="section-lk-data-slogan">GREENFIELD CLUB</div>
                  <div className="section-lk-data-content">
                    <div className="section-lk-data-content-1 data">
                      <div className="data-name">{this.props.data.state.name}</div>
                      <div
                        className="btn-arrow-1 data-edit data-edit-password"
                        onClick={() => {
                          this.changeShow();
                        }}>
                        сменить пароль
                      </div>
                      <a href={'mailto:' + this.props.data.state.email}>
                        {this.props.data.state.email}
                      </a>
                      <a href={'tel:+' + this.props.data.state.phone}>
                        +{this.props.data.state.phone}
                      </a>
                      <div className="data-address">
                        <div className="data-title">АДРЕС ДОСТАВКИ:</div>
                        {this.props.data.state.city === '' && (
                          <div
                            className="btn-arrow-1 data-edit data-edit-address"
                            onClick={() => {
                              this.addDataShow();
                            }}>
                            указать адрес доставки
                          </div>
                        )}
                        <p>
                          {this.props.data.state.postalIndex
                            ? this.props.data.state.postalIndex
                            : ''}
                          {this.props.data.state.city ? ' г. ' + this.props.data.state.city : ''}{' '}
                          {this.props.data.state.street
                            ? ', ул. ' + this.props.data.state.street
                            : ''}{' '}
                          {this.props.data.state.home}
                          {this.props.data.state.flat ? ', кв. ' + this.props.data.state.flat : ''}
                        </p>
                      </div>
                    </div>
                    <div className="section-lk-data-content-2">
                      <div id="ball" className="anchor"></div>
                      <div className="data-ball">
                        {this.props.data.state.balance} <span>баллов</span>
                      </div>
                      <div className="data-btn-box">
                        <a
                          href="#prize"
                          className={'btn btn-prize btn-prize-'}
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'change',
                            });
                          }}>
                          ОБМЕНЯТЬ БАЛЛЫ НА ПОДАРОК
                        </a>
                        <button
                          id="apm-scan-qr2"
                          className={'btn upload-check custom-main-button btn-1 btn-arrow-1'}
                          onClick={() => {
                            // document.getElementById('apm-scan-qr').click();
                            this.info8Show();
                            window.dataLayer.push({
                              event: 'gaEvent',
                              eventCategory: 'internal',
                              eventAction: 'click',
                              eventLabel: 'register_check',
                            });
                          }}>
                          Загрузить чек
                        </button>
                      </div>
                    </div>
                    <div className="section-lk-data-content-img">
                      <div className="section-lk-data-content-img-i section-lk-data-content-img-1"></div>
                      <div className="section-lk-data-content-img-i section-lk-data-content-img-2"></div>
                      <div className="section-lk-data-content-img-i section-lk-data-content-img-3"></div>
                      <div className="section-lk-data-content-img-i section-lk-data-content-img-4"></div>
                      <div className="section-lk-data-content-img-i section-lk-data-content-img-5"></div>
                    </div>
                  </div>
                </div>
                <div className="section-lk-slider">
                  <div id="prize" className="anchor"></div>
                  <div className="section-title-2">
                    <span>ПОДАРКИ</span>
                  </div>
                  <div className="d-col-12 d-flex section-prizes">{this.renderPrizes()}</div>
                </div>
                <div className="section-lk-container">
                  <div id="check" className="anchor"></div>
                  <div className="container">
                    <div className="bg-section section-lk-content">
                      <div className="section-title-2 d-flex text-center">
                        <span className=" text-center">ЗАГРУЖЕННЫЕ ЧЕКИ</span>
                      </div>

                      <div className="tab__pane tab__pane-billa fadeIn animated active">
                        <div className="table__wrap">
                          <div className="table__head">
                            <div className="table__row d-flex align-items-start m-hidden">
                              <div
                                className={
                                  'tb__col tb__col-3 d-col-4 tb__th' +
                                  (this.state.prizeType == 0 ? ' active' : '')
                                }
                                onClick={() => {
                                  this.setState({ page: 1, prizeType: 0 });
                                  this.getCodes(1, 0);
                                }}>
                                история операций
                              </div>
                              <div
                                className={
                                  'tb__col tb__col-3 d-col-4 tb__th' +
                                  (this.state.prizeType == 1 ? ' active' : '')
                                }
                                onClick={() => {
                                  this.setState({ page: 1, prizeType: 1 });
                                  this.getCodes2(1, 1);
                                }}>
                                чеки
                              </div>
                              <div
                                className={
                                  'tb__col tb__col-3 d-col-4 tb__th' +
                                  (this.state.prizeType == 2 ? ' active' : '')
                                }
                                onClick={() => {
                                  this.setState({ page: 1, prizeType: 2 });
                                  this.getCodes(1, 2);
                                }}>
                                заказанные подарки
                              </div>
                            </div>
                            <div className="d-hidden table__row align-items-start">
                              <Carousel
                                renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
                                  `0${currentSlide + 1}`
                                }
                                wrapAround={true}
                                scrollMode={'page'}
                                slidesToShow={1}
                                wrapAround={false}>
                                <div
                                  className={
                                    'tb__col tb__col-3 d-col-4 tb__th' +
                                    (this.state.prizeType == 0 ? ' active' : '')
                                  }
                                  onClick={() => {
                                    this.setState({ page: 1, prizeType: 0 });
                                    this.getCodes(1, 0);
                                  }}>
                                  история операций
                                </div>
                                <div
                                  className={
                                    'tb__col tb__col-3 d-col-4 tb__th' +
                                    (this.state.prizeType == 1 ? ' active' : '')
                                  }
                                  onClick={() => {
                                    this.setState({ page: 1, prizeType: 1 });
                                    this.getCodes(1, 1);
                                  }}>
                                  чеки
                                </div>
                                <div
                                  className={
                                    'tb__col tb__col-3 d-col-4 tb__th' +
                                    (this.state.prizeType == 2 ? ' active' : '')
                                  }
                                  onClick={() => {
                                    this.setState({ page: 1, prizeType: 2 });
                                    this.getCodes(1, 2);
                                  }}>
                                  заказанные подарки
                                </div>
                              </Carousel>
                            </div>
                          </div>
                          <div className="table__body" data-count={this.state.codes.length}>
                            <div className="table__scroll2">
                              <div className="table__rows">{this.renderWinners('id')}</div>
                            </div>
                          </div>
                          {/* колесо загрузки */}
                          {/* <div
                            className={
                              'codes-loader ' + (this.state.loading_codes === true ? 'active' : '')
                            }></div> */}
                        </div>
                      </div>
                      {this.state.perPage > 0 ? (
                        <ReactPaginate
                          previousLabel={'prev'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageCount={this.state.perPage}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          previousLabel={'◄'}
                          nextLabel={'►'}
                          activeClassName={'active'}
                        />
                      ) : (
                        ''
                      )}
                      {/* <div className="btn-load-more">Загрузить еще</div> */}
                    </div>
                  </div>
                </div>
                <div className="section-lk-myOrder">
                  <div id="order" className="anchor"></div>
                  <div className="mini-container">
                    <div className="section-title-2">
                      <span>Мои заказы</span>
                    </div>
                    <div className="section-lk-myOrder-items">{this.renderPrizeMy()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-logo">
            <div className="footer-logo-box">GREENFIELD CLUB</div>
            <div className="footer-btn">
              <a
                href="/"
                className="footer-btn-main"
                onClick={() => {
                  window.dataLayer.push({
                    event: 'gaEvent',
                    eventCategory: 'internal',
                    eventAction: 'click',
                    eventLabel: 'back_main',
                  });
                }}>
                <span className="ico ico-arrow-green"></span>вернуться на главную
                <span className="ico ico-star-green"></span>
              </a>
            </div>
          </div>
          <Modals
            link={this.props.link}
            getPrizeMy={this.getPrizeMy}
            runWidget={this.runWidget}
            checkShow={this.checkShow}
            data={this.props.data}
            ref={(instance) => {
              this.modals = instance;
            }}
          />
          <div id="apm-widget"></div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
